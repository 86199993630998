import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Layout from "../views/Layout";
import store from "../store";
import Menu from "../Menu";
import TelemarketingView from "@/views/Telemarketing.vue";
import GuiaView from "@/components/Print/Guia.vue"; 

const LoginPage = () => import("@/views/Login");

Vue.use(VueRouter);

const VerificaAcesso = (to, from, next) => {
  store.dispatch("auth/isAuthenticated").then((res) => {
    if (res) {
      let pgs = Menu.reduce((obj, vl) => {
        if (
          vl.permissao.includes("*") ||
          vl.permissao.includes(store.state.auth.user.perfil)
        ) {
          obj.push(vl);
        }
        if (vl.children && vl.children.length > 0) {
          vl.children.map((it) => {
            if (
              it.permissao.includes("*") ||
              it.permissao.includes(store.state.auth.user.perfil)
            ) {
              obj.push(it);
            }
          });
        }
        return obj;
      }, []);
      // console.log('pgs', pgs);
      if (to.path == "" || to.path == "/") {
        next(Menu[0].link);
      } else if (pgs.find((x) => x.link == to.path.substr(1)) != null) {
        next();
      } else {
        next("/status/404");
      }
    } else {
      next("/login");
    }
  });
};

const routes = [
  {
    path: "/pix/:id",
    name: "PIX",
    component: () => import("@/views/Pix.vue"),
  },
  {
    path: "/anamnese/:id",
    name: "Paciente Anamnese",
    component: () => import("@/views/PacienteAnamnese.vue"),
  },
  {
    path: "/guia/:id",
    name: "Guia",

    component: GuiaView,
  },
  {
    path: "/print",
    name: "Printer",
    beforeEnter(to, from, next) {
      VerificaAcesso(to, from, next);
    },
    component: () => import("@/components/Print/PrintComponent.vue"),
  },
  {
    path: "/anexarFichas",
    name: "Anexar Fichas",
    beforeEnter(to, from, next) {
      VerificaAcesso(to, from, next);
    },
    component: () => import("@/views/AnexarFichas.vue"),
  },
  {
    path: "/",
    component: Layout,
    beforeEnter(to, from, next) {
      VerificaAcesso(to, from, next);
    },
    children: [
      {
        path: "/notificacoes",
        name: "Notificações",
        component: () => import("@/views/Notificacoes.vue"),
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("@/views/Home.vue"),
      },

      {
        path: "corpo_clinico",
        name: "CorpoClinico",
        component: () =>
          import("@/views/processos/corpoClinico/CorpoClinico.vue"),
      },
      {
        path: "atendimento",
        name: "Atendimento",
        props: {
          atendimento: true,
          solicitacoes: false,
        },
        component: () => import("@/views/processos/Agendas.vue"),
      },
      {
        path: "cids",
        name: "CIDs",
   
        component: () => import("@/components/common/CIDCatalog.vue"),
      },
      {
        path: "pedido",
        name: "Pedido",
        // component: ()=> import('@/components/pedido/pedido.vue')
      },
      {
        path: "/agenda",
        name: "Agenda",
        component: () => import("@/views/processos/Agendas.vue"),
      },
      {
        path: "/caixa",
        name: "Caixa",
        component: () => import("@/views/processos/caixas/Caixa.vue"),
      },
      {
        path: "/pacientes",
        name: "Pacientes",
        props: {
          tipo: "paciente",
        },
        component: () => import("@/views/Pacientes.vue"),
      },

      {
        path: "telemarketing",
        name: "Telemarketing",
        component: TelemarketingView,
        children: [
          {
            path: "home",
            name: "home",
            component: () => import("@/components/telemarketing/home.vue"),
          },
          {
            path: "consultas",
            name: "consultas",
            props: {
              tipo: "consultas",
            },
            component: () => import("@/components/telemarketing/tlmk.vue"),
          },
          {
            path: "cartao",
            name: "cartao",
            props: {
              tipo: "cartao",
            },
            component: () => import("@/components/telemarketing/tlmk.vue"),
          },
          {
            path: "pos-venda",
            name: "pos-venda",
            props: {
              tipo: "pos-venda",
            },
            component: () => import("@/components/telemarketing/tlmk.vue"),
          },
        ],
      },

      {
        path: "/cadastros/agenda",
        name: "Cadastro Agenda",
        component: () => import("@/views/cadastros/Agendas.vue"),
      },
      {
        path: "/cadastros/usuarios",
        name: "Usuários",
        component: () => import("@/views/cadastros/Usuarios.vue"),
      },
      {
        path: "/cadastros/clinicas",
        name: "Clínicas",
        component: () => import("@/views/cadastros/Clinicas.vue"),
      },
      {
        path: "/cadastros/empresas",
        name: "Empresas",
        component: () => import("@/views/cadastros/Empresas.vue"),
      },

      {
        path: "/cadastros/especialidades",
        name: "Especialidades",
        component: () => import("@/views/cadastros/Especialidades.vue"),
      },
      {
        path: "/cadastros/medicos",
        name: "Medicos",
        props: {
          tipo: "medico",
        },
        component: () => import("@/views/cadastros/Entidades.vue"),
      },
      {
        path: "/cadastros/funcionarios",
        name: "Funcionário",
        props: {
          tipo: "funcionario",
        },
        component: () => import("@/views/cadastros/Entidades.vue"),
      },
      {
        path: "/cadastros/fornecedores",
        name: "Fornecedores",
        props: {
          tipo: "fornecedor",
        },
        component: () => import("@/views/cadastros/Entidades.vue"),
      },
      {
        path: "/cadastros/laboratorios",
        name: "Laboratórios",
        props: {
          tipo: "laboratorio",
        },
        component: () => import("@/views/cadastros/Entidades.vue"),
      },
      {
        path: "/cadastros/convenios",
        name: "Convenio",
        props: {
          tipo: "convenio",
        },
        component: () => import("@/views/cadastros/Entidades.vue"),
      },

      {
        path: "/cadastros/entidades",
        name: "Entidades",
        props: {
          tipo: "Geral",
        },
        component: () => import("@/views/cadastros/Entidades.vue"),
      },
      {
        path: "/cadastros/medicamentos",
        name: "Medicamentos",
        component: () => import("@/views/cadastros/Medicamentos.vue"),
      },
      {
        path: "/cadastros/exames",
        name: "Exames",
        component: () => import("@/views/cadastros/Exames.vue"),
      },
      {
        path: "/cadastros/tipos_de_divulgacao",
        name: "Tipos de Divulgação",
        component: () => import("@/views/cadastros/TiposDeDivulgacao.vue"),
      },
      {
        path: "/cadastros/anamneses",
        name: "Anamneses",
        component: () => import("@/views/cadastros/Anamnese.vue"),
      },
      {
        path: "/cadastros/fidelizacao",
        name: "Fidelização",
        component: () => import("@/views/cadastros/Fidelizacao.vue"),
      },
      {
        path: "/cadastros/centros_de_custo",
        name: "centrosDeCusto",
        component: () => import("@/views/cadastros/CentrosDeCusto.vue"),
      },
      {
        path: "/cadastros/bandeiras",
        name: "cartaoBandeiras",
        component: () => import("@/views/cadastros/Bandeiras.vue"),
      },
      {
        path: "/cadastros/estabelecimentos",
        name: "estabelecimentos",
        component: () => import("@/views/cadastros/Estabelecimentos.vue"),
      },
      {
        path: "/cadastros/contas_pagar_categorias",
        name: "Categorias Contas a Pagar",
        component: () => import("@/views/cadastros/ContasPagarCategorias.vue"),
      },

      {
        path: "/home",
        name: "Home",
        component: Home,
      },
      {
        path: "/about",
        name: "About",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/About.vue"),
      },
      {
        path: "/relatorios/aniversarios",
        name: "Aniversários",
        component: () => import("@/views/relatorios/Aniversarios.vue"),
      },
      {
        path: "relatorios/vendasConvenio",
        name: "VendasXConvenio",
        component: () => import("@/views/relatorios/VendasConvenio.vue"),
      },  
       {
        path: "relatorios/vendas",
        name: "Vendas",
        component: () => import("@/views/relatorios/Vendas.vue"),
      },
      {
        path: "/relatorios/comparativo/consultas",
        name: "RelatórioComparativoConsultas",
        component: () => import("@/views/relatorios/Consultas/Comparativo.vue"),
      },
      {
        path: "/relatorios/guias",
        name: "RelatorioGuiasMedicas",
        component: () => import("@/views/relatorios/Guias.vue"),
      }, //relatorios/faturamento

      {
        path: "/relatorios/faturamento",
        name: "RelatorioFaturamento",
        component: () => import("@/views/relatorios/Faturamento.vue"),
      }, //relatorios/faturamento
      {
        path: "relatorios/aproveitamento/otc",
        name: "RelatorioAproveitamentoOTC",
        component: () => import("@/views/relatorios/AproveitamentoOTC.vue"),
      },
      {
        path: "relatorios/contas_pagar/pagamentosLucro",
        name: "RelatorioPagamentoComLucro",
        component: () =>
          import(
            "@/views/relatorios/Financeiro/ContasPagar/FaturasComLucratividade.vue"
          ),
      },
      {
        path: "unificarCadastros",
        name: "unificarCadastros",
        component: () => import("@/views/Acoes/UnificarCadastros.vue"),
      },
      {
        path: "corrigirCustoVenda",
        name: "corrigirCustoVenda",
        component: () => import("@/views/Acoes/CorrecaoCustosVendas.vue"),
      },
      {
        path: "financeiro/contas_receber/faturas",
        name: "ContasReceberFaturas",
        component: () => import("@/views/financeiro/ContasReceber/Faturas.vue"),
      },

      {
        path: "financeiro/contas_pagar/faturas",
        name: "ContasPagarFaturas",
        component: () => import("@/views/financeiro/ContasPagar/Faturas.vue"),
      },
    ],
  },

  {
    path: "/login",
    name: "Login",
    beforeEnter(to, from, next) {
      if (store.state.auth.user && store.state.auth.user.token) {
        next(Menu[0].link);
      } else {
        store.dispatch("auth/isAuthenticated").then((res) => {
          if (res) {
            next(Menu[0].link);
          } else {
            next();
          }
        });
      }
    },
    component: LoginPage,
  },

  {
    path: "/logout",
    name: "Logout",
    component: () => {
      store.state.globalLoading = true;
      store.dispatch("auth/logout").then((q) => {
        if (q) {
          window.location.href = "/";
        }
      });
    },
  },
  {
    path: "/",
    name: "RedirectHome",
    component: (next) => {
      next("/login");
    },
  },
  {
    path: "/status/404",
    name: "Status404",
    component: () => import("../views/status/404.vue"),
  },
  {
    path: "/recebimento-antecipado",
    name: "RecebimentoAntecipado",
    component: () => import("@/views/RecebimentoAntecipado.vue"),
    beforeEnter: VerificaAcesso
  },
  {
    path: "/payment/:recebimento_id/:entidade_id/:amount/:payerName",
    name: "payment",
    component: () => import("@/views/Payment.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/payment-success",
    name: "payment-success",
    component: () => import("@/views/PaymentSuccess.vue"),
    meta: { requiresAuth: false }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
